import React from 'react';
import './UpdateBotManagement.scss';
import { connect } from 'react-redux';
// eslint-disable-next-line max-len
import BotManagementDetailInputs from '../../components/BotManagementDetailInputs/BotManagementDetailInputs';

function UpdateBotManagement({ ...props }) {
  return (
    <div>
      <BotManagementDetailInputs isEdit={true} />
    </div>
  );
}
const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateBotManagement);
