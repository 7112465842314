/* eslint-disable max-len */
import React, { useEffect } from 'react';
import './Marketplace.scss';
import { Button, Container, Col, Card, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import {
  getBotRequestAction,
  getRegisteredBotRequestAction,
  registerRequest
} from '../../store/bot/botActions';
import {
  LineChart, Line,
  CartesianGrid
} from 'recharts';
import { setLoadingAction, setLoadingFullAction } from '../../store/loading/loadingActions';
import { useHistory } from 'react-router-dom';
import {
  accountDetailsRequestAction
} from '../../store/account/userActions';
import bnbusdt from '../../assets/img/coin/bnbusdt.png';
import high from '../../assets/img/risk/high.png';
import medium from '../../assets/img/risk/medium.png';
import low from '../../assets/img/risk/low.png';
import { Popover, OverlayTrigger } from 'react-bootstrap';
import MercurXIcons from '../../components/MercurXIcons/MercurXIcons';
import { mainColors } from '../../helpers/colors';

const data = [
  { name: 'a', uv: 12, pv: -10 },
  { name: 'b', uv: 19, pv: 0 },
  { name: 'c', uv: 30, pv: 5 },
  { name: 'c', uv: 30, pv: 3 },
  { name: 'c', uv: 30, pv: 8 },
  { name: 'c', uv: 30, pv: 20 },
  { name: 'c', uv: 30, pv: 1 },
];

function Marketplace({ ...props }) {
  const {
    botData,
    registeredBotData,
    getBotRequest,
    getRegisteredBotRequest,
    setLoadingFull,
    registerRequest,
    user,
    accountDetailsRequest,
  } = props;

  const history = useHistory();

  useEffect(() => {
    getBotRequest();
  }, [getBotRequest]);
  useEffect(() => {
    accountDetailsRequest();
  }, []);
  useEffect(() => {
    getRegisteredBotRequest();
  }, [getRegisteredBotRequest]);

  const botDataArray = botData ? Object.values(botData) : [];

  const routeStrategie = async (e, id) => {
    history.push(`/general-settings/bot-management/${id}`);
  };
  const redirectApiKey = async () => {
    history.push('/general-settings/api-settings');
  };
  const handleCardClick = (id) => {
    history.push(`/bot-detail/${id}`);
  };
  const riskPopover = (risk) => (
    <Popover id={`popover-${risk}`}>
      <Popover.Body>
        {risk === 'low' && 'This bot is designed for low-risk investments, prioritizing stability and safety. It operates with a conservative approach, entering fewer trades to ensure higher reliability and consistent performance.'}
        {risk === 'medium' && 'This bot is optimized for moderate-risk investments, offering a balance between risk and reward. It executes a reasonable number of trades to maximize growth potential while maintaining controlled risk levels. However, there is a possibility of moderate losses in fluctuating markets.'}
        {risk === 'high' && 'This bot is tailored for high-risk investments, focusing on aggressive strategies to achieve maximum returns. It enters frequent trades, leveraging market volatility for higher profit potential. Be aware, though, that losses can be significant in high-risk scenarios.'}
      </Popover.Body>
    </Popover>
  );
  return (
    <Container>
      <div className="text-fs-head-sm text-start">Strategies</div>
      <div className="border-0 mt-4 position-relative">
        <Row className="mb-5">
          {botDataArray.map((item, index) => {
            const isBotRegistered = registeredBotData?.bots.some(
              bot => bot.bot_id === item.id && bot.registration_status === true
            );
            const exchange = isBotRegistered ? 'Binance' : null;

            return (
              <Col key={index} sm={12} md={4}>
                <Card className="marketplace-card py-2 px-3 shadow">
                  <div className="text-fs-head-xs mx-auto">{item.name}</div>
                  <div className="d-flex justify-content-center align-items-center">
                    {item.status ? 'Active' : 'Inactive'}
                  </div>
                  <hr />
                  <LineChart width={300} height={200} data={data}>
                    <CartesianGrid stroke="#0000" strokeDasharray="5 5" />
                    <Line type="monotone" dataKey="pv" stroke="#097875" />
                  </LineChart>

                  <hr />
                  <div className="marketplace-card-content">
                    <div className="text-fs-body-md">
                      • Creator: {item.creator}
                    </div>
                    <div className="text-fs-body-md">
                      • Created Date: {new Date(item.cdate).toLocaleString()}
                    </div>
                    <div className="text-fs-body-md d-flex align-items-center">
                      • Risk:
                      <span className={`risk-value-${item.risk}`} >
                        {item.risk}
                      </span>
                      <img
                        src={
                          item.risk === 'low'
                            ? low
                            : item.risk === 'medium'
                              ? medium
                              : item.risk === 'high'
                                ? high
                                : null
                        }
                        alt={`${item.risk} risk`}
                        width={24}
                        height={24}
                        style={{ marginLeft: 8 }}
                      />
                      <OverlayTrigger
                        placement="top"
                        overlay={riskPopover(item.risk)}
                      >
                        <span style={{ marginLeft: 8, cursor: 'pointer' }}>
                          <MercurXIcons name="FaInfoCircle" color={mainColors.primary} />
                        </span>
                      </OverlayTrigger>
                    </div>
                    <div className="text-fs-body-md">
                      • {item.exchanges.length > 1 ? 'Exchanges' : 'Exchange'}
                      : {item.exchanges && item.exchanges.length > 0
                        ? item.exchanges.map((exchange, idx) => (
                          <span key={idx}>
                            {exchange.name.charAt(0).toUpperCase() +
                              exchange.name.slice(1).toLowerCase()}
                            {idx < item.exchanges.length - 1 && ", "}
                          </span>
                        ))
                        : null}
                      <img
                        src={bnbusdt}
                        width={24}
                        height={24}
                        style={{ marginLeft: 2, marginBottom: 3 }}
                      />
                    </div>
                  </div>
                  <hr />
                  <Row>
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        if (!user?.api_key_status) {
                          redirectApiKey();
                        } else {
                          routeStrategie(e, item.id);
                        }
                      }}
                      disabled={user?.api_key_status &&
                        isBotRegistered
                      }
                      className={'btn btn-primary'}
                    >
                      <span className="ms-1">
                        {!user?.api_key_status ? 'Add API Key' :
                          isBotRegistered ?
                            `Registered - ` : 'Add Exchange'
                        }
                        {isBotRegistered && item.exchanges.map((exchange, idx) => (
                          <span key={idx}>
                            {exchange.name.charAt(0).toUpperCase() +
                              exchange.name.slice(1).toLowerCase()}
                            {idx < item.exchanges.length - 1 && ", "}
                          </span>
                        ))}

                      </span>
                    </Button>

                    {!user?.api_key_status ? null : isBotRegistered && (
                      <Button
                        onClick={(e) => {
                          e.stopPropagation();
                          routeStrategie(e, item.id);
                        }}
                        className={'btn btn-primary mt-2'}
                      >
                        Add Exchange
                      </Button>
                    )}
                  </Row>
                </Card>
              </Col>
            );
          })}
        </Row>
      </div>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    botData: state.botReducer.botData,
    registeredBotData: state.botReducer.registeredBotData,
    user: state.userReducer.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBotRequest: (payload) => {
      dispatch(getBotRequestAction(payload));
    },
    getRegisteredBotRequest: (payload) => {
      dispatch(getRegisteredBotRequestAction(payload));
    },
    setLoading: (payload) => {
      dispatch(setLoadingAction(payload));
    },
    setLoadingFull: (payload) => {
      dispatch(setLoadingFullAction(payload));
    },
    registerRequest: (creds) => {
      dispatch(registerRequest(creds));
    },
    accountDetailsRequest: (payload) => {
      dispatch(accountDetailsRequestAction(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Marketplace);
