import React from 'react';
import './BotDetail.scss';
import {
  Container, Col, Dropdown,
  Form, ButtonGroup, Row, Button,
  Pagination
} from 'react-bootstrap';
import BotHistoryTable from '../../components/BotHistoryTable/BotHistoryTable';
import BotHistoryChart from '../../components/BotHistoryChart/BotHistoryChart';
import {
  getBotHistoryRequestAction,
  getCustomBotHistoryRequestAction,
  setBotHistorySortDataAction,
  sortingBotHistoryAction,
} from '../../store/bot/botActions';
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import MercurXBackButton from '../../components/MercurXBackButton/MercurXBackButton';
import { useParams, useLocation } from 'react-router-dom';
import MercurXIcons from '../../components/MercurXIcons/MercurXIcons';
import bnbusdt from '../../assets/img/coin/bnbusdt.png';
import * as XLSX from 'xlsx';
import { setLoadingFullAction } from '../../store/loading/loadingActions';

function BotDetail({ ...props }) {
  const {
    botHistoryData,
    botHistoryCountData,
    getBotHistoryRequest,
    botHistorySortData,
    setBotHistorySortData,
    sortingBotHistory,
    getBotHistoryRequestAction,
    getCustomBotHistoryRequestAction,
    setLoadingFull
  } = props;

  const { id } = useParams();
  const location = useLocation();
  const item = location.state?.item;
  const exchanges = location.state?.exchanges;
  const [dateRange, setDateRange] = useState("1_day");
  const [selectedRange, setSelectedRange] = useState("1_day");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [limit, setLimit] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortDir, setSortDir] = useState("asc");
  const [sortColumn, setSortColumn] = useState("buy_timestamp");
  const [loading, setLoading] = useState(true);
  function changeSortDir(direction) {
    setSortDir(direction);
    sortingBotHistory({ sort_dir: direction, sort_column: sortColumn });
  }

  function changeSortColumn(column) {
    setSortColumn(column);
    sortingBotHistory({ sort_dir: sortDir, sort_column: column });
  }
  useEffect(() => {
    const bot_id = id;
    const payload = {
      bot_id,
      dateRange,
      limit_start: (currentPage - 1) * limit,
      limit_end: currentPage * limit,
      sort_dir: sortDir,
      sort_column: sortColumn,
    };
  
    setLoading(true);
    setLoadingFull({ isLoading: true, loadingText: 'Loading Bot History...' });
  
    getBotHistoryRequestAction(payload);
  
    const timeoutId = setTimeout(() => {
      setLoading(false);
      setLoadingFull({ isLoading: false, loadingText: '' });
    }, 1000);
  
    return () => clearTimeout(timeoutId);
  }, [botHistorySortData, dateRange, limit, currentPage, sortDir,
     sortColumn, id, getBotHistoryRequestAction, setLoadingFull]);
  
  useEffect(() => {
    getBotHistoryRequest();
    sortingBotHistory();
  }, []);

  function changeSortType(sortType) {
    const newBotHistorySortData = { ...botHistorySortData, sortType };
    setBotHistorySortData(newBotHistorySortData);
    sortingBotHistory();
  }

  function changeSortKey(sortKey) {
    const newBotHistorySortData = { ...botHistorySortData, sortKey };
    setBotHistorySortData(newBotHistorySortData);
    sortingBotHistory();
  }

  function handleDateRangeChange(range) {
    setCurrentPage(1);
    setDateRange(range);
    setSelectedRange(range);
  }

  function handleCustomSearch() {
    if (startDate && endDate) {
      const payload = {
        bot_id: id,
        startDate,
        endDate,
        limit_start: (currentPage - 1) * limit,
        limit_end: currentPage * limit,
        sort_dir: sortDir,
        sort_column: sortColumn,
      };
      setSelectedRange("");
      setLoading(true);
      setLoadingFull({ isLoading: true, loadingText: 'Loading Custom Bot History...' });
  
      getCustomBotHistoryRequestAction(payload)
      const timeoutId = setTimeout(() => {
        setLoading(false);
        setLoadingFull({ isLoading: false, loadingText: '' });
      }, 1000);
    
      return () => clearTimeout(timeoutId);
    } else {
      console.error("Both start and end dates are required for custom search.");
    }
  }

  const formatRangeLabel = (range) => {
    return range.replace('_', ' ').replace(/\b\w/g, char => char.toUpperCase());
  };

  const handleNextPage = () => {
    setCurrentPage(prevPage => prevPage + 1);
  };

  const handlePreviousPage = () => {
    setCurrentPage(prevPage => (prevPage > 1 ? prevPage - 1 : 1));
  };

  const [txs, setTxs] = useState([]);
  const maxRowCountPerPage = limit;
  const maxShowingPage = 5;

  const [pages, setPages] = useState([]);
  const [renderedData, setRenderedData] = useState([]);




  const changePage = (page) => {
    setCurrentPage(page);
    scrollToBotHistoryContainer();
  };

  const scrollToBotHistoryContainer = () => {
    const botHistory = document.getElementById('bot-history-container');
    if (botHistory) {
      botHistory.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const firstPage = () => {
    setCurrentPage(1);
    scrollToBotHistoryContainer();
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      scrollToBotHistoryContainer();
    }
  };

  const nextPage = () => {
    if (currentPage < pages.length) {
      setCurrentPage(currentPage + 1);
      scrollToBotHistoryContainer();
    }
  };

  const lastPage = () => {
    setCurrentPage(pages.length);
    scrollToBotHistoryContainer();
  };

  const formatValue = (value) => {
    return value?.toLocaleString('tr-TR');
  };

  useEffect(() => {
    const totalItems = botHistoryCountData?.count;
    const totalPages = Math.ceil(totalItems / limit);
    setPages(Array.from({ length: totalPages }, (_, i) => i + 1));
  }, [limit, botHistoryCountData]);

  const pageRange = pages.slice(
    Math.max(0, currentPage - Math.floor(maxShowingPage / 2) - 1),
    Math.min(pages.length, currentPage + Math.floor(maxShowingPage / 2))
  );

  const columnDisplayNames = {
    buy_timestamp: "Buy Timestamp",
    profit_loss: "Profit Loss",
    profit_loss_percentage: "Profit Loss Percentage",
  };

  const exportToExcel = () => {
    const headers = [
      { header: "Order Id", key: "buy_order_id" },
      { header: "Token", key: "token" },
      { header: "Profit Loss $", key: "profit_loss" },
      { header: "Profit Loss %", key: "profit_loss_percentage" },
      { header: "Average Unit Buy Price", key: "avg_buy_price" },
      { header: "Average Unit Sell Price", key: "avg_sell_price" },
      { header: "Total Buy Qty", key: "total_buy_qty" },
      { header: "Total Buy Cost", key: "total_buy_cost" },
      { header: "Total Sell Qty", key: "total_sales_qty" },
      { header: "Total Sell Cost", key: "sales_proceeds" },
      { header: "Buy Timestamp", key: "buy_timestamp" },
      { header: "Sell Timestamp", key: "sales_timestamp" },
      { header: "Status", key: "status" },
    ];

    const formattedData = botHistoryData.map((item) => {
      const formattedItem = {};
      headers.forEach(({ key, header }) => {
        if (key === "profit_loss") {
          formattedItem[header] = item[key] > 0 ? `+${item[key]}` : item[key];
        } else if (key === "status") {
          formattedItem[header] = item[key] ? "Open" : "Closed";
        } else {
          formattedItem[header] = item[key];
        }
      });
      return formattedItem;
    });

    const worksheet = XLSX.utils.json_to_sheet(formattedData, {
      header: headers.map((h) => h.header),
    });

    worksheet["!cols"] = [
      { wpx: 73 },
      { wpx: 60 },
      { wpx: 67 },
      { wpx: 70 },
      { wpx: 120 },
      { wpx: 120 },
      { wpx: 73 },
      { wpx: 77 },
      { wpx: 73 },
      { wpx: 77 },
      { wpx: 109 },
      { wpx: 109 },
      { wpx: 39 },
    ];

    const profitLossColumnIndex = headers.findIndex((h) => h.header === "Profit Loss $") + 1;
    Object.keys(worksheet).forEach((cell) => {
      const cellRef = XLSX.utils.decode_cell(cell);
      if (cellRef.c === profitLossColumnIndex - 1 && cellRef.r > 0) {
        const cellValue = worksheet[cell].v;
        if (typeof cellValue === "string" && cellValue.startsWith("+")) {
          worksheet[cell].s = { font: { color: 'red' } };
        } else if (typeof cellValue === "string" && cellValue.startsWith("-")) {
          worksheet[cell].s = { font: { color: { rgb: "FF0000" } } };
        }
      }
    });

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "BotHistory");
    XLSX.writeFile(workbook, "BotHistory.xlsx");
  };
  return (
    <Container>
      <MercurXBackButton buttonText="Back" />
      <div className="text-fs-head-sm text-start">Bot Detail</div>
      <div className="bot-chart-container">
        <BotHistoryChart />
      </div>
      {exchanges && exchanges[0]?.name && (
        <div className="exchange-name mx-3 text-end">
          <strong>Exchange: </strong>
          {exchanges[0].name.charAt(0).toUpperCase() + exchanges[0].name.slice(1).toLowerCase()}
          <img
            src={bnbusdt}
            width={24}
            height={24}
            style={{ marginLeft: 2, marginBottom: 3 }}
            alt="Exchange Logo"
          />
        </div>
      )}

      <Row className="mb-3">
        <Col xs={12} md={6}>
          <ButtonGroup className="d-flex flex-wrap">
            {["1_day", "1_week", "1_month", "3_month", "6_month", "1_year", "All"].map((range) => (
              <Button
                key={range}
                onClick={() => handleDateRangeChange(range)}
                variant={selectedRange === range ? "primary" : "outline-light"}
                className={`mb-2 ${selectedRange === range ? "flex-fill" : "text-primary"}`}
              >
                {formatRangeLabel(range)}
              </Button>
            ))}
          </ButtonGroup>
        </Col>
        <Col xs={5} sm={4} md={3}>
          <Form.Group>
            <Form.Control
              type="date"
              value={startDate}
              onChange={(e) => {
                const selectedDate = e.target.value;
                setStartDate(selectedDate);
                if (endDate && new Date(selectedDate) > new Date(endDate)) {
                  setEndDate("");
                }
              }}
              max={endDate}
              placeholder="Start Date"
            />
          </Form.Group>
        </Col>
        <Col xs={5} sm={4} md={3}>
          <Form.Group>
            <Form.Control
              type="date"
              value={endDate}
              onChange={(e) => {
                const selectedDate = e.target.value;
                setEndDate(selectedDate);
                if (startDate && new Date(selectedDate) < new Date(startDate)) {
                  setStartDate("");
                }
              }}
              min={startDate}
              placeholder="End Date"
            />
          </Form.Group>
        </Col>
        <Col xs={5} sm={4} md={5} ></Col>
        <Col xs={5} sm={4} md={6} ></Col>
        <Col xs={2} sm={4} md={1} className="d-flex align-items-end">
          <Button
            variant="primary"
            className="text-fs-body-sm d-flex justify-content-center w-100"
            onClick={handleCustomSearch}
          >
            <MercurXIcons name="FaSearch" className="" size="12" />
          </Button>
        </Col>
      </Row>

      <Row className="d-flex align-items-center mb-3">
        <Col xs={12} md={8} className="d-flex flex-wrap gap-2">
          <Dropdown>
            <Dropdown.Toggle size="sm" className="w-100 w-md-auto">
              Sort: {sortDir === "asc" ? "Ascending" : "Descending"}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {["asc", "desc"].map((dir) => (
                <Dropdown.Item key={dir} onClick={() => changeSortDir(dir)}>
                  {dir === "asc" ? "Ascending" : "Descending"}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown>
            <Dropdown.Toggle size="sm" className="w-100 w-md-auto">
              {columnDisplayNames[sortColumn]}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {Object.keys(columnDisplayNames).map((column) => (
                <Dropdown.Item key={column} onClick={() => changeSortColumn(column)}>
                  {columnDisplayNames[column]}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown>
            <Dropdown.Toggle size="sm" variant="primary" className="w-100 w-md-auto">
              Limit: {limit}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {[5, 10, 15, 20, 50, 100, "All"].map((item) => (
                <Dropdown.Item
                  key={item}
                  onClick={() => setLimit(item === "All" ? 100000 : item)}
                >
                  {item}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </Col>
        <Col xs={12} md={4} className="d-flex justify-content-end">
          {botHistoryData?.length > 0 && (
            <Button
              variant="primary"
              className="d-flex justify-content-center"
              onClick={exportToExcel}
            >
              <MercurXIcons className="mt-1 mx-1" name="FaRegFileExcel" size="13" />
              <div style={{ fontSize: "13px" }}>Export to Excel</div>
            </Button>
          )}
        </Col>
      </Row>
      {botHistoryData?.length > 0 && (
        <>
          <Pagination className="d-flex justify-content-center">
            <Pagination.First onClick={firstPage} disabled={currentPage === 1} />
            <Pagination.Prev onClick={prevPage} disabled={currentPage === 1} />
            {pageRange.map((page, index) => (
              <Pagination.Item
                key={index}
                active={page === currentPage}
                onClick={() => changePage(page)}
              >
                {page}
              </Pagination.Item>
            ))}
            <Pagination.Next onClick={nextPage} disabled={currentPage === pages.length} />
            <Pagination.Last onClick={lastPage} disabled={currentPage === pages.length} />
          </Pagination>
        </>
      )}
      <BotHistoryTable limit={limit} currentPages={currentPage} />
      {botHistoryData?.length > 0 && (
        <>
          <Pagination className="d-flex justify-content-center">
            <Pagination.First onClick={firstPage} disabled={currentPage === 1} />
            <Pagination.Prev onClick={prevPage} disabled={currentPage === 1} />
            {pageRange.map((page, index) => (
              <Pagination.Item
                key={index}
                active={page === currentPage}
                onClick={() => changePage(page)}
              >
                {page}
              </Pagination.Item>
            ))}
            <Pagination.Next onClick={nextPage} disabled={currentPage === pages.length} />
            <Pagination.Last onClick={lastPage} disabled={currentPage === pages.length} />
          </Pagination>
        </>
      )}
    </Container>
  );
  
}

const mapStateToProps = (state) => {
  return {
    botHistoryData: state.botReducer.botHistoryData,
    botHistoryCountData: state.botReducer.botHistoryCountData,
    botStatisticsData: state.botReducer.botStatisticsData,
    botHistorySortData: state.botReducer.botHistorySortData,
    isLoading: state.loadingReducer.isLoading,
    user: state.userReducer.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBotHistoryRequest: (payload) => {
      dispatch(getBotHistoryRequestAction(payload));
    },
    setBotHistorySortData: (payload) => {
      dispatch(setBotHistorySortDataAction(payload));
    },
    sortingBotHistory: (payload) => {
      dispatch(sortingBotHistoryAction(payload));
    },
    getBotHistoryRequestAction: (creds) => {
      dispatch(getBotHistoryRequestAction(creds));
    },
    getCustomBotHistoryRequestAction: (creds) => {
      dispatch(getCustomBotHistoryRequestAction(creds));
    },
    setLoadingFull: (payload) => {
      dispatch(setLoadingFullAction(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BotDetail);
