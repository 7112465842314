import { useEffect, useState } from 'react';
import { Badge, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import './BotHistoryTable.scss';

function BotHistoryTable(props) {
  const { botHistoryData, limit,currentPages } = props;
  const [txs, setTxs] = useState([]);
  const maxRowCountPerPage = limit;
  const maxShowingPage = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const [pages, setPages] = useState([]);
  const [renderedData, setRenderedData] = useState([]);

  useEffect(() => {
    if (botHistoryData) {
      const totalPages = Math.ceil(20 / maxRowCountPerPage);
      setPages(Array.from({ length: totalPages }, (_, index) => index + 1));
    }
  }, [botHistoryData, limit]);

  useEffect(() => {
    if (botHistoryData) {
      const totalPages = Math.ceil(botHistoryData.length / maxRowCountPerPage);
      setPages(Array.from({ length: totalPages }, (_, index) => index + 1));
      const firstIndex = (currentPage - 1) * maxRowCountPerPage;
      const lastIndex = currentPage * maxRowCountPerPage;
      const pageData = botHistoryData.slice(firstIndex, lastIndex);
      setRenderedData(pageData);
    } else {
      setRenderedData([]);
      setPages([]);
    }
  }, [botHistoryData, currentPage, limit]);

  const changePage = (page) => {
    setCurrentPage(page);
    scrollToBotHistoryContainer();
  };

  const scrollToBotHistoryContainer = () => {
    const botHistory = document.getElementById('bot-history-container');
    if (botHistory) {
      botHistory.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const firstPage = () => {
    setCurrentPage(1);
    scrollToBotHistoryContainer();
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      scrollToBotHistoryContainer();
    }
  };

  const nextPage = () => {
    if (currentPage < pages.length) {
      setCurrentPage(currentPage + 1);
      scrollToBotHistoryContainer();
    }
  };

  const lastPage = () => {
    setCurrentPage(pages.length);
    scrollToBotHistoryContainer();
  };

  const formatValue = (value) => {
    return value?.toLocaleString('tr-TR');
  };



  return (
    <>
      
      <Table className="bot-history-table" responsive hover borderless>
        <thead>
          <tr className="text-t-body-color text-fs-tag">
            <th>#</th>
            <th>Order Id</th>
            <th>Token</th>
            <th>Profit Loss $</th>
            <th>Profit Loss %</th>
            <th>Average Unit Buy Price</th>
            <th>Average Unit Sell Price</th>
            <th>Total Buy Qty</th>
            <th>Total Buy Cost</th>
            <th>Total Sell Qty</th>
            <th>Total Sell Cost</th>
            <th>Buy Timestamp</th>
            <th>Sell Timestamp</th>
            <th>Status</th>
          </tr>
        </thead>
        {renderedData?.length ? (
          <tbody>
            {renderedData.map((item, index) => {
              const rowIndex = (currentPages - 1) * maxRowCountPerPage + index + 1;
              if (currentPage * index < currentPage * maxRowCountPerPage) {
                return (
                  <tr key={index} className="text-t-head-color">
                    <td>{rowIndex}</td>
                    <td>{item.buy_order_id}</td>
                    <td>{item.token}</td>
                    <td>
                      {item.profit_loss > 0 ? (
                        <Badge bg="success" style={{
                          fontSize: '1em'
                        }}>{'$' + formatValue(item.profit_loss)}</Badge>
                      ) : (
                        <Badge bg="danger">{'$' + formatValue(item.profit_loss)}</Badge>
                      )}
                    </td>
                    <td>{item.profit_loss_percentage}%</td>
                    <td>{'$' + formatValue(item.avg_buy_price)}</td>
                    <td>{'$' + formatValue(item.avg_sell_price)}</td>
                    <td>{formatValue(item.total_buy_qty)}</td>
                    <td>{'$' + formatValue(item.total_buy_cost)}</td>
                    <td>{formatValue(item.total_sales_qty)}</td>
                    <td>{'$' + formatValue(item.sales_proceeds)}</td>
                    <td>{item.buy_timestamp}</td>
                    <td>{item.sales_timestamp}</td>
                    <td>
                      {item.status ? (
                        <Badge bg="success">Open</Badge>
                      ) : (
                        <Badge bg="danger">Closed</Badge>
                      )}
                    </td>
                  </tr>
                );
              }
            })}
          </tbody>
        ) : null}
      </Table>

      {renderedData.length === 0 || renderedData.status_code === 400 ? (
        <div className="d-flex text-fs-body-md text-t-body-color justify-content-center">
          No Bot history found.
        </div>
      ) : null}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    botHistoryData: state.botReducer.botHistoryData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(BotHistoryTable);
