import { connect } from 'react-redux';
import MercurXFooter from '../../components/MercurXFooter/MercurXFooter';
import MercurXNavbar from '../../components/MercurXNavbar/MercurXNavbar';
// import MercurXSidebarMenu from '../../components/MercurXSidebarMenu/MercurXSidebarMenu';
import { useEffect } from 'react';
import './MainLayout.scss';

function MainLayout({ ...props }) {
  const { children } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  // return (
  //   <div className="main-layout-container">
  //     <div className="main-layout-left"></div>
  //     <MercurXNavbar {...props} />
  //     <div className="main-layout-content">
  //       <div className="row">
  //         <div className="col-3">
  //           <div className="main-layout-sidebar position-fixed">
  //             <MercurXSidebarMenu />
  //           </div>
  //         </div>
  //         <div className="col main-layout-content-inner">
  //           {children}
  //           <MercurXFooter />
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // );
  return (
    <div class="main-layout-container">
        <MercurXNavbar {...props} />
      <div class="main-layout-content">
        <div class="row">
          <div class="col main-layout-content-inner">
            {children}
            <MercurXFooter />
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    MainLayoutStatus: 'True',
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MainLayout);