import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import './BotHistoryChart.scss';
import {
  AreaChart, Area, CartesianGrid, XAxis, YAxis,
  Tooltip, ResponsiveContainer, Label
} from 'recharts';
import { Col, Row, Button, ButtonGroup } from 'react-bootstrap';

function BotHistoryChart(props) {
  const { botHistoryData } = props;

  const [loading, setLoading] = useState(true);
  const [totalPnl, setTotalPnl] = useState(0);
  const [totalPnlPercent, setTotalPnlPercent] = useState(0);
  const [displayInDollars, setDisplayInDollars] = useState(true);

  useEffect(() => {
    if (!botHistoryData || botHistoryData.length === 0) {
      setTotalPnl(0);
      setTotalPnlPercent(0);
    } else {

      const totalPnlValue = botHistoryData.reduce(
        (acc, item) => acc + item.profit_loss, 0);
      const totalPnlPercentValue = botHistoryData.reduce(
        (acc, item) => acc + item.profit_loss_percentage, 0);

      setTotalPnl(totalPnlValue);
      setTotalPnlPercent(totalPnlPercentValue);
    }
  }, [botHistoryData]);


  const mappedData = botHistoryData
    ? botHistoryData
      .filter(item => item.sales_timestamp !== "Open")
      .map(item => ({
        time: item.buy_timestamp,
        pnl: displayInDollars ? item.profit_loss : item.profit_loss_percentage,
      }))
    : [];

  const gradientOffset = () => {
    const dataMax = Math.max(...mappedData.map((i) => i.pnl));
    const dataMin = Math.min(...mappedData.map((i) => i.pnl));

    if (dataMax <= 0) return 0;
    if (dataMin >= 0) return 1;
    return dataMax / (dataMax - dataMin);
  };

  const off = gradientOffset();

  const handleToggleMetric = (metric) => {
    setDisplayInDollars(metric === 'dollars');
  };

  return (
    <Col>
      <Row className="d-flex justify-content-between align-items-center">
        <Col sm={12} md={1} className="d-flex justify-content-start">
          <ButtonGroup className="d-flex button-group-bordered">
            <Button
              variant={displayInDollars ? 'primary' : 'white'}
              onClick={() => handleToggleMetric('dollars')}
              className="mr-2"
            >
              $
            </Button>
            <Button
              variant={!displayInDollars ? 'primary' : 'white'}
              onClick={() => handleToggleMetric('percentage')}
            >
              %
            </Button>
          </ButtonGroup>
        </Col>

        <Col sm={12} md={11}>
          <ResponsiveContainer width="100%" height={360}>
            <AreaChart
              data={mappedData}
              margin={{ top: 5, right: 30, left: 20, bottom: 30 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="time"
                tick={({ x, y, payload }) => {
                  const [date, time] = payload.value.split(' ');
                  return (
                    <g transform={`translate(${x},${y + 10})`}>
                      <text x={0} y={0} dy={16} textAnchor="middle" fill="#666">
                        <tspan x="0" dy="0">{date}</tspan>
                        <tspan x="0" dy="15">{time}</tspan>
                      </text>
                    </g>
                  );
                }}
              >
                <Label
                  value="Time"
                  position="insideBottom"
                  offset={-30}
                  style={{ textAnchor: 'middle', fontWeight: 'bold', fill: '#555' }}
                />
              </XAxis>

              <YAxis>
                <Label
                  value={displayInDollars ? 'PNL ($)' : 'PNL (%)'}
                  angle={-90}
                  offset={-5}
                  position="insideLeft"
                  style={{ textAnchor: 'middle', fontWeight: 'bold', fill: '#555' }}
                />
              </YAxis>
              <Tooltip />
              <defs>
                <linearGradient id="splitColor" x1="0" y1="0" x2="0" y2="1">
                  <stop offset={off} stopColor="green" stopOpacity={1} />
                  <stop offset={off} stopColor="red" stopOpacity={1} />
                </linearGradient>
              </defs>
              <Area
                type="monotone"
                dataKey="pnl"
                stroke="url(#splitColor)"
                fill="url(#splitColor)"
              />
            </AreaChart>
          </ResponsiveContainer>
        </Col>
      </Row>

      <Row className="general-pnl d-flex justify-content-end align-items-center m-2">
        <Col className="d-flex flex-row align-items-center gap-1 mx-4 mb-2 ">
          <div>Total PNL</div>
          <div style={{ color: totalPnl < 0 ? 'red' : 'green' }}>
            {displayInDollars ? totalPnl.toFixed(2) : totalPnlPercent.toFixed(2)}{" "}
            {displayInDollars ? "($)" : "(%)"}
          </div>
        </Col>
      </Row>
    </Col>
  );
}

const mapStateToProps = (state) => {
  return {
    botHistoryData: state.botReducer.botHistoryData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BotHistoryChart);
